<template>
  <div class="functionTypes">
    <a-spin :indicator="indicator" :spinning="spinning">

      <div class="table_Info">
<!--        <a-button type="primary" @click="()=>this.addShow = true"> 新增</a-button>-->
        <a-table :columns="columns" style="min-height: 500px;margin-top: 10px"  :data-source="InfoTable" :pagination="false" rowKey="id">

          <span slot="operate" slot-scope="text,record">
            <a-switch v-model="record.is_open=== 1 ? true:false" checked-children="是" un-checked-children="否" @click="switchOpen(record.id,record.is_open === 1 ? 0 : 1)" />
          </span>
          <span slot="home_func" slot-scope="text,record">
            <a-switch v-model="record.is_home_func=== 1 ? true:false" checked-children="是" un-checked-children="否" @click="funcOpen(record.id,record.is_home_func === 1 ? 0 : 1)" />
          </span>
          <span slot="edit" slot-scope="item">
             <a style="margin-right: 10px" @click="editBtn(item.id)"> 修改</a>
             <a-popconfirm
                 title="确定删除?"
                 ok-text="是"
                 cancel-text="否"
                 @confirm="delRules(item.id)"
                 @cancel="cancel"
             >
            <a >删除</a>
            </a-popconfirm>
           </span>
        </a-table>
        <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
          <a-pagination v-model="pagination.current"
                        :total="pagination.total"
                        @change="tableChange" />
        </div>
      </div>


      <a-modal v-model="addShow" title="新增" @ok="addOk">
        <a-form-model :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" hideRequiredMark>
          <a-form-model-item label="功能名称" prop="name">
            <a-input v-model="addForm.name" />
          </a-form-model-item>
          <a-form-model-item label="code值" prop="code">
            <a-input v-model="addForm.code" />
          </a-form-model-item>
          <a-form-model-item label="功能访问地址" prop="url">
            <a-input v-model="addForm.url" type="textarea" οninput="value=value.replace(/\s+/g,’’)" />
          </a-form-model-item>
          <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
            <div class="switches">九宫格功能: &nbsp;<a-switch @change="funcWitch" checked-children="是" un-checked-children="否" default-un-checked /></div>
            <div class="switches">是否开启: &nbsp;<a-switch @change="openWitch" checked-children="是" un-checked-children="否" default-un-checked /></div>
          </div>

        </a-form-model>
      </a-modal>
      <a-modal v-model="editShow" title="修改" @ok="editOk">
        <a-form-model :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" hideRequiredMark>
          <a-form-model-item label="功能名称" prop="name">
            <a-input v-model="editForm.name" />
          </a-form-model-item>
          <a-form-model-item label="code值" prop="code">
            <a-input v-model="editForm.code" />
          </a-form-model-item>
          <a-form-model-item label="功能访问地址" >
            <a-input v-model="editForm.url" type="textarea" />
          </a-form-model-item>
          <div style="display: flex;width: 75%;flex-wrap: wrap;padding: 15px;justify-content: space-between;height: 100px">
            <div class="switches">九宫格功能: &nbsp;
              <a-switch :checked="editForm.is_home_func!==0" @click="funcEdit(editForm.is_home_func === 1? 0 : 1)"
                        checked-children="是" un-checked-children="否"  />
            </div>
            <div class="switches">是否开启: &nbsp;
              <a-switch :checked="editForm.is_open!==0" @click="openEdit(editForm.is_open === 1? 0 : 1)"
                        checked-children="是" un-checked-children="否"  />
            </div>

          </div>

        </a-form-model>
      </a-modal>


    </a-spin>
  </div>
</template>

<script>
import {
  delFunctionTypes,
  getFunctionTypes, getType,
  postFunctionTypes, putFunctionTypes,
  putTypesOpen
} from "@/service/medicalConference_api";



export default {
  name: "functionTypes",
  data(){
    return{
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      InfoTable:[],
      addShow:false,
      editShow:false,
      addForm:{
        is_open: 0,
        is_home_func:0,
      },
      editForm:{
      },
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      rules: {
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入code', trigger: 'blur' }],

      },
      columns:[
        {
          title: '功能名称',
          dataIndex: 'name',
        },
        {
          title: 'Code',
          dataIndex: 'code',
        },
        {
          title: '页面地址',
          dataIndex: 'url',
        },

        {
          title: '是否开启',
          scopedSlots: { customRender: 'operate' },
        },
        {
          title: '是否九宫格功能',
          scopedSlots: { customRender: 'home_func' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_time',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'edit' },
        },
      ],
    }
  },

  created() {
    this.$store.dispatch('setManageHeaderTitle',"首页功能类型管理")
  },
  mounted() {
    this.getFunctionType()
  },
  methods:{
    async getFunctionType(pageNum){
      this.spinning = true
      const Info = await getFunctionTypes(pageNum)
      if (Info.code === 0){
        this.InfoTable = Info.data.types
        this.pagination.total = Info.data.count
      }
      this.spinning = false
    },
    //模板地址是否开启
    async switchOpen(id, open){
      const data = {
        is_open : open
      }
      const info =await putTypesOpen(id,data)
      if (info.code === 0){
        await this.getFunctionType(this.pagination.current)
        this.$message.success('修改成功')
      }
    },
    async funcOpen(id,open){
      const data = {
        is_home_func : open
      }
      const info =await putTypesOpen(id,data)
      if (info.code === 0){
        await this.getFunctionType(this.pagination.current)
        this.$message.success('修改成功')
      }
    },
    //添加
    async addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const addFrom = await postFunctionTypes(this.addForm)
          if (addFrom.code === 0){
            await this.getFunctionType(this.pagination.current)
            this.$message.success("添加成功")
            this.addShow=false
            this.$refs.addItem.resetFields();
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //九宫格功能
    funcWitch(checked){
      if (checked === false){
        this.addForm.is_home_func = 0
      }else {
        this.addForm.is_home_func = 1
      }
    },
    //是否开启
    openWitch(checked){
      if (checked === false){
        this.addForm.is_open = 0
      }else {
        this.addForm.is_open = 1
      }
    },
    async editBtn(id){
      const Info =await getType(id)
      this.editForm = Info.data
      this.editShow = true
    },
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const id = this.editForm.id
          const edit = await putFunctionTypes(id,this.editForm)
          if (edit.code === 0){
            await this.getFunctionType(this.pagination.current)
            this.$refs.editItem.resetFields();
            this.$message.success("修改成功")
            this.editShow=false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    openEdit(open){
      this.editForm.is_open = open
    },
    funcEdit(open){
      this.editForm.is_home_func = open
    },


    //删除模板地址
    async delRules(id){
      const del = await delFunctionTypes(id)
      if (del.code === 0 ){
        await this.getFunctionType(this.pagination.current)
        this.$message.success('删除成功')
      }
      else {
        this.$message.error('删除失败')
      }
    },
    tableChange(e){
      this.getFunctionType(e)
    },
    cancel(){
      this.$message.warning('取消删除');
    },

  },
}
</script>

<style lang="scss" scoped>
.functionTypes{
  margin: -15px -15px;
  .table_Info{
    width: 96%;
    margin: 0 auto;
    padding: 10px;
  }
  .switches{
    width: 30%;
    margin:10px auto;
    text-align: right;
  }
}
</style>
